import { BigNumber } from '@ethersproject/bignumber'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useMulticallContract } from './useContract'

// gets the current timestamp from the blockchain
export default function useCurrentBlockTimestamp(): BigNumber | undefined {
  const multicall = useMulticallContract()
  const result = useSingleCallResult({
    contract: multicall,
    functionName: 'getCurrentBlockTimestamp'
  })?.result

  return BigNumber.from(result || '0')
}
